import { Layout } from 'antd'

import MenuSider from '../Chat/widgets/sider'
import HeaderMenu from '../Chat/widgets/header'
import { Outlet } from 'react-router-dom'

const MainLayout = () => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <MenuSider />
            <Layout>
                <HeaderMenu />
                <Outlet />
            </Layout>
        </Layout>
    )
}

export default MainLayout