
import { createRoot } from 'react-dom/client';
import Login from './components/Login';
import { ConfigProvider, theme } from "antd";
import './components/index.css';
import axios from "axios";
import esES from 'antd/locale/es_ES';
import Chat from './components/Chat/Chat';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivateRoute from './router/PrivateRouter';
import MainLayout from './components/Layout/MainLayout';
import Documents from './components/Documents/Documents';
import Vehicles from './components/Vehicles/Vehicles';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');
const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <PrivateRoute component={MainLayout} />

        ),
        children: [
            //    { path: "/", element: <Chat /> },
            { path: "/intranet", element: <Documents /> },
            { path: "/intranet/:id", element: <Documents /> },
            { path: "/autos", element: <Vehicles /> },

        ]
    },
    { path: "/login", element: <Login /> },
]);


axios.defaults.baseURL = import.meta?.env.VITE_APP_API;

const accessToken = localStorage.getItem("token") ?? '';
axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

const container = document.querySelector('#root');
const root = createRoot(container);


root.render(
    <ConfigProvider locale={esES} >
        <RouterProvider router={router} />
    </ConfigProvider>
);
