import axios from 'axios';

import { create } from 'zustand';

export interface LoginData {
    codigo: string;
    claveAcceso: string;
}

export interface RegisterData extends LoginData {
    fullname: string;
    phone: string;
}

export interface ValidationError {
    message: string;
    errors: Record<string, string[]>
}

interface AuthState {
    isAuthenticated: () => boolean,
    error: string | null,
    isLoading: boolean,
    setToken: () => void,
    login: (data: LoginData) => Promise<void>,
    register: (data: RegisterData) => Promise<void>,
    logout: () => void
}

export const useAuthStore = create<AuthState>((set, get) => {

    return {
        isAuthenticated: () => {
            let local = localStorage.getItem("token");
            return local !== null ? true : false;
        },
        error: null,
        isLoading: false,
        setToken: () => {
            localStorage.getItem("token") ?? ''

        },

        login: async (form: LoginData) => {
            try {
                set({ isLoading: !get().isLoading })
                const { data } = await axios.post('/users/signin', form);

                if (data?.['accessToken'] !== null) {
                    localStorage.setItem("token", data?.['accessToken']);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${data?.['accessToken']}`;
                } else {
                    set({ error: 'Error al iniciar sesion' })
                    setTimeout(() => {
                        set({ error: null })
                    }, 2500);
                }



            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 3000);

            } finally {
                set({ isLoading: !get().isLoading })
            }
        },
        register: async (data: RegisterData) => {
            try {
                set({ isLoading: !get().isLoading })
                await axios.post('/auth/signup', data);

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 500) {
                        messageError = 'Error. datos ';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);
            } finally {
                set({ isLoading: !get().isLoading })
            }
        },
        logout: () => {
            localStorage.removeItem("token");
        }
    }
})