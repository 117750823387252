import { Content } from "antd/es/layout/layout";
import { Button, Col, Divider, Dropdown, MenuProps, Modal, Row, Space, Table, Tag, theme, Typography } from "antd";
import { useVehiclesState } from "../store/vehicles/vehicles.store";
import { useEffect, useState } from "react";
import { DataType } from "../store/vehicles/vehicle.interface";
import { TableProps } from "antd/lib";
import dayjs from "dayjs";
import { DownOutlined, FileExcelOutlined, PictureOutlined } from '@ant-design/icons'
import DescripcionVehicle from "./DescripcionVehicle";
import Title from "antd/es/typography/Title";
import convertDataToExcel from 'data-to-xlsx';


const Vehicles = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const getVehicles = useVehiclesState(state => state.getVehicles);
    const vehicles = useVehiclesState(state => state.vehicles);
    const setSelectedVehicle = useVehiclesState(state => state.setSelectedVehicle);
    const selectedVehicle = useVehiclesState(state => state.selectedVehicle);
    const clearSelectedVehicle = useVehiclesState(state => state.clearSelectedVehicle);



    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageSize, setPageSize] = useState<number>(20);
    const [modalWidth, setModalWidth] = useState(window.innerWidth > 768 ? '50vw' : '100vw');
    const updateModalWidth = () => {
        setModalWidth(window.innerWidth > 768 ? '50vw' : '100vw');
    };
    useEffect(() => {

        getVehicles();
        // Add event listener on mount
        window.addEventListener('resize', updateModalWidth);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', updateModalWidth);
        };

    }, [])


    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Opciones',
            fixed: 'left',
            key: 'opciones',
            render: (_, record) => (
                <Space size={'small'}>
                    <Button title="Mostrar Fotos" icon={<PictureOutlined />} onClick={() => {
                        setSelectedVehicle(record);
                        setIsModalVisible(true)
                    }} />
                </Space>
            )
        },
        {
            title: 'Placa',
            fixed: 'left',
            dataIndex: 'placa',
            key: 'placa'
        },
        {
            title: 'Tipo',
            dataIndex: 'tipoVehiculo',
            key: 'tipoVehiculo'
        },
        {
            title: 'Marca',
            dataIndex: 'marcaVehiculo',
            key: 'marcaVehiculo'
        },
        {
            title: 'Modelo',
            dataIndex: 'modeloVehiculo',
            key: 'modeloVehiculo'
        },
        {
            title: 'Año',
            dataIndex: 'anio',
            key: 'anio'
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color'
        },
        {
            title: 'Kilometraje',
            dataIndex: 'kilometraje',
            key: 'kilometraje'
        },
        {
            title: 'AireAcondicionado',
            dataIndex: 'aireAcondicionado',
            key: 'aireAcondicionado'
        },
        {
            title: 'Precio',
            dataIndex: 'precio',
            key: 'precio'
        },
        {
            title: 'Cilindraje',
            dataIndex: 'cilindraje',
            key: 'cilindraje'
        },
        /*  {
               title: 'MatriculadoHasta',
               dataIndex: 'matriculadoHasta',
               key: 'matriculadoHasta'
           },
           {
               title: 'Chasis',
               dataIndex: 'chasis',
               key: 'chasis'
           },*/
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (estado: string) => {
                switch (estado) {
                    default:
                        return <Tag color={'green'} key={'estado'}>Disponible</Tag>
                }
            },
        },
        /* {
             title: 'Fecha',
             dataIndex: 'fecha',
             key: 'fecha',
             render: (fecha: Date) => (<Typography.Text>{dayjs(fecha).locale('ES').format('dd/MM/YYYY')}</Typography.Text>)
         },
         {
             title: 'Observacion',
             dataIndex: 'observacion',
             key: 'observacion',
         },*/

        {
            title: 'Combustible',
            dataIndex: 'tipoCombustible',
            key: 'tipoCombustible'
        },
        {
            title: 'Direccion',
            dataIndex: 'tipoDireccion',
            key: 'tipoDireccion'
        },
        {
            title: 'Vidrios',
            dataIndex: 'tipoVidrio',
            key: 'tipoVidrio'
        },
        {
            title: 'Transmision',
            dataIndex: 'tipoTransmision',
            key: 'tipoTransmision'
        },
        {
            title: 'Traccion',
            dataIndex: 'tipoTraccion',
            key: 'tipoTraccion'
        },
        {
            title: 'Tapizado',
            dataIndex: 'tipoTapizado',
            key: 'tipoTapizado'
        },
        /* {
             title: 'Ubicacion',
             dataIndex: 'ubicacionVehiculo',
             key: 'ubicacionVehiculo'
         },
         {
             title: 'Propietario',
             dataIndex: 'propietarioVehiculo',
             key: 'propietarioVehiculo'
         },
         {
             title: 'Oficina',
             dataIndex: 'oficinaAuto',
             key: 'oficinaAuto'
         },*/


    ];
    const handleMenuClick: MenuProps['onClick'] = () => {

        onDownloadReport();
    };

    const items: MenuProps['items'] = [
        {
            label: 'Descargar Reporte Excel',
            key: '1',
            icon: <FileExcelOutlined />,

        },

    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const onDownloadReport = () => {
        convertDataToExcel({
            data: vehicles.map(v => {
                const { key, ...rest } = v; // Desestructuración para eliminar `key`
                return rest;
            }),
            fileName: 'Reporte de Vehiculos ' + dayjs().format('DD-MM-YYYY')
        })
    }


    return (
        <>

            <Content style={{ padding: '48px 10px' }}>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 280,
                        padding: 24,

                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <Title level={4}>Reporte de Vehiculos</Title>
                        </Col>
                        {
                            vehicles.length > 0 ? (
                                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    <Dropdown menu={menuProps} >
                                        <Button>
                                            <Space>
                                                Acciones
                                                <DownOutlined twoToneColor="#52c41a" />
                                            </Space>
                                        </Button>
                                    </Dropdown>

                                </Col>
                            ) : null
                        }
                    </Row>
                    <Divider />
                    <Table

                        size="small"

                        pagination={{
                            pageSize: pageSize,
                            onShowSizeChange(_, size) {
                                setPageSize(size);
                            },
                        }}
                        bordered
                        columns={columns}
                        dataSource={vehicles}
                        scroll={{ x: 'max-content' }} />
                </div>

            </Content>
            <Modal
                title={'Detalles Vehiculo'}
                centered
                open={isModalVisible}
                onClose={() => {
                    setIsModalVisible(false)
                    clearSelectedVehicle()
                }}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={modalWidth}
            >
                <DescripcionVehicle key={selectedVehicle?.id ?? 1} selectedVehicle={selectedVehicle!} />
            </Modal>
        </>
    )
}

export default Vehicles