import { create } from "zustand";
import { DataType, PhotoVehicleI } from "./vehicle.interface";
import axios from "axios";
import { ValidationError } from "../auth/auth.store";

interface VehiclesState {
    vehicles: DataType[],
    isLoading: boolean,
    selectedVehicle: DataType | null
    error: string | null,
    getVehicles: () => Promise<void>,
    setSelectedVehicle: (selectedVechicle: DataType) => void,
    photosVehicle: PhotoVehicleI[],
    getPhotosVehicle: (idVehicle: number) => void,
    clearSelectedVehicle: () => void,
}

export const useVehiclesState = create<VehiclesState>((set, get) => {
    return {
        vehicles: [],
        isLoading: false,
        error: null,
        selectedVehicle: null,
        photosVehicle: [],
        getVehicles: async () => {
            try {
                set({
                    isLoading: !get().isLoading
                })
                const { data } = await axios.get('/vehicles');


                set({
                    vehicles: (data as Array<any>).map((d, i) => ({
                        ...d,
                        key: (i + 1) + ''
                    }))
                });

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoading: !get().isLoading })
            }
        },
        setSelectedVehicle: (selectedVechicle: DataType) => {

            set({ selectedVehicle: selectedVechicle });
        },
        getPhotosVehicle: async (idVehicle: number) => {
            try {
                set({
                    isLoading: !get().isLoading,
                    photosVehicle: []
                })
                const { data } = await axios.get('/files/vehicle/' + idVehicle);


                set({
                    photosVehicle: data
                });

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoading: !get().isLoading })
            }
        },
        clearSelectedVehicle: () => {
            set({ selectedVehicle: null })
        }
    }

})