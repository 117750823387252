import axios from "axios";
import { create } from "zustand"
import { ValidationError } from "../auth/auth.store";
import { DirectoryI } from "./directory.interface";
import { RootDirectoryI } from "./rootDirectory.interface";
import { FileI } from "./file.interface";

interface FilesState {
    currentDir: DirectoryI[] | RootDirectoryI[],
    isLoadingDir: boolean,
    isLoadingFile: boolean,
    error: string | null,
    idDir: number,

    file: FileI | null,
    getDir: (idDir?: number) => Promise<void>,
    getFile: (idFile: number, idDir: number) => Promise<void>,

}

export const useFilesState = create<FilesState>((set) => {
    return {
        currentDir: [],
        isLoadingDir: false,
        isLoadingFile: false,
        file: null,
        error: null,
        idDir: -1,
        getDir: async (idDir?: number) => {
            try {
                set({
                    isLoadingDir: true,
                    idDir: !idDir ? -1 : idDir
                })
                const { data } = await axios.get(!idDir ? '/files/intranet' : ('/files/intranet/' + idDir));


                set({
                    currentDir: !idDir ? (data as RootDirectoryI[]).map(d => ({ ...d, type: 'root' })) : (data as DirectoryI[]).map(d => ({ ...d, type: 'directory' })),

                });

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoadingDir: false })
            }
        },
        getFile: async (idFile: number, idDir: number) => {
            try {
                set({
                    isLoadingFile: true,
                    idDir: idDir,
                    file: null
                })
                const { data, headers } = await axios.get('/files/download/' + idFile, {
                    responseType: 'blob',
                });


                set({
                    file: {
                        file: data,
                        type: headers['content-type']
                    }
                });

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoadingFile: false })
            }
        },

    }
})