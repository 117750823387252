
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, theme, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { MenuProps } from 'antd/lib';
import { useAuthStore } from '../../store/auth/auth.store';
import { useNavigate } from 'react-router-dom';

const HeaderMenu = () => {
    const logout = useAuthStore(state => state.logout)
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();



    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Typography.Text>
                    Mi Perfil
                </Typography.Text>
            ),
            icon: <UserOutlined />,

        },

        {
            key: '2',
            label: (
                <Typography.Text>
                    Cerrar Sesion
                </Typography.Text>
            ),
            icon: <LogoutOutlined />,
            onClick: () => {
                logout();
                navigate('/login');
            }

        },



    ]

    return (
        <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', justifyContent: 'end' }}>
            <div style={{
                paddingRight: 20
            }}>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <Button type="text" style={{ padding: 0 }}>
                        <Avatar size={32} icon={<UserOutlined />} />
                    </Button>
                </Dropdown>
            </div>
        </Header>
    )
}

export default HeaderMenu