import { Button, Col, Divider, Empty, Modal, Row, Space, Spin, theme, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { useNavigate, useParams } from 'react-router-dom';
import { useFilesState } from "../store/files/files.store";
import { useEffect, useState } from "react";

import FolderIcon from "./svg/Folder.icon";
import IconType from "./IconType";
import DocViewer, { DocViewerRenderers } from "@hchuang/react-doc-viewer";
import { DirectoryI } from "../store/files/directory.interface";
import { saveAs } from "file-saver";

const { Text } = Typography;
const Documents = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [visibleModal, setVisibleModal] = useState(false);
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const currentDir = useFilesState(state => state.currentDir);
    const isLoadingDir = useFilesState(state => state.isLoadingDir);

    const isLoadingFile = useFilesState(state => state.isLoadingFile);
    const getDir = useFilesState(state => state.getDir);
    const idDir = useFilesState(state => state.idDir);


    const getFile = useFilesState(state => state.getFile);
    const file = useFilesState(state => state.file);


    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        // Asegúrate de que getDir se ejecute cada vez que el id cambie
        if (id) {
            getDir(parseInt(id));
        } else {
            getDir(); // Llama a getDir sin id para obtener la raíz si no hay id
        }
    }, [id, getDir]);

    useEffect(() => {

        if (file) {


            const blob = new Blob([file.file], { type: file.type });

            const blobUrl = URL.createObjectURL(blob);
            setFileUrl(blobUrl);
        }
        return () => {

            setFileUrl(null);
        };

    }, [file])


    useEffect(() => {
        if (fileUrl != null) {
            // if pdf
            if (file?.type === 'application/pdf') {
                setVisibleModal(true);
            } else {
                const nameFile = (currentDir.find((c) => c.id === idDir) as DirectoryI).nombreArchivo;
                saveAs(
                    new Blob([file?.file!], {
                        type: file?.type,
                    }),
                    nameFile
                );
            }
            //if another file
        }    // Create a Blob from the buffer

    }, [fileUrl])





    return (
        <Content style={{ padding: '48px 10px' }}>
            <div
                style={{
                    background: colorBgContainer,
                    minHeight: 280,
                    padding: 24,

                    borderRadius: borderRadiusLG,
                }}
            >
                <Row>
                    <Col span={12}>
                        <Title level={4}>Intranet</Title>
                    </Col>

                </Row>
                <Divider />
                <Spin spinning={isLoadingDir || isLoadingFile}>

                    {
                        currentDir.length > 0 ? (
                            <Space size={[6, currentDir.length / 6]} wrap>
                                {
                                    currentDir.map((d, i) => {
                                        const SIZE_ICON = 42;
                                        if (d.type == 'root') {

                                            return <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, }}>

                                                <Button
                                                    style={{ border: 0, boxShadow: 'none' }}
                                                    icon={<FolderIcon size={SIZE_ICON} />}
                                                    onClick={() => {
                                                        navigate('/intranet/' + d.id);
                                                    }}
                                                >
                                                    <Text strong>{d.nombre}</Text>

                                                </Button>

                                            </div>
                                        } else {

                                            return <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, }}>

                                                <Button

                                                    style={{ border: 0, boxShadow: 'none' }}
                                                    icon={<IconType typeNode={d.tipoNodo} name={d.nombreArchivo} size={SIZE_ICON} />}
                                                    onClick={() => {
                                                        if (d.tipoNodo === 'C') {
                                                            navigate('/intranet/' + d.id);
                                                        } else {
                                                            getFile(d.idArchivo, d.id);
                                                        }
                                                    }}
                                                >
                                                    <Text strong>{d.nombre}</Text>

                                                </Button>

                                            </div>
                                        }
                                    })
                                }
                            </Space>
                        ) : (
                            <Row>
                                <Col span={24}>
                                    <Empty />
                                </Col>
                            </Row>


                        )
                    }
                </Spin>

            </div>

            <Modal
                width={'100%'}
                title={null}
                height={'100vh'}
                centered
                open={visibleModal}
                onCancel={() => setVisibleModal(false)}
                footer={null}
            >
                <DocViewer
                    key={idDir}
                    style={{ height: '90vh' }}
                    documents={[{
                        uri: fileUrl!,
                        fileName: (currentDir.find((c) => c.id === idDir) as DirectoryI)?.nombreArchivo
                    }]}
                    pluginRenderers={DocViewerRenderers}
                />
            </Modal>
        </Content>
    )
}

export default Documents