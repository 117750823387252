import { Button, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import {

    DiffOutlined,
    FormOutlined,
    FolderOutlined,
    DatabaseOutlined,
    CarOutlined
} from '@ant-design/icons';
import { useChatStore } from '../../store/chat/chat.store';
import { NavLink } from 'react-router-dom';

const MenuSider = () => {
    const emptyChat = useChatStore(state => state.emptyChat)
    return (
        <Sider
            theme="dark"
            breakpoint="lg"
            collapsedWidth="0"
        >
            <div style={{ display: 'flex', height: 60, justifyContent: 'end' }}>
                <Button title='Nuevo chat' type="text" style={{ paddingTop: 20, color: 'white' }} onClick={emptyChat}>
                    <FormOutlined style={{ fontSize: 24 }} />
                </Button>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                items={[
                    /*   {
                           key: '1',
                           icon: <DiffOutlined />,
                           label: <NavLink to={'/'}>Chat</NavLink>
   
   
   
                       },*/
                    {
                        key: '2',
                        icon: <FolderOutlined />,
                        label: <NavLink to={'/intranet'}>Intranet</NavLink>,
                    },
                    /*  {
                          key: '3',
                          icon: <DatabaseOutlined />,
                          label: 'Reportes',
                      },*/
                    {
                        key: '3',
                        icon: <CarOutlined />,
                        label: <NavLink to={'/autos'}>Autos</NavLink>,

                    },

                ]}
            />
        </Sider>
    )
}

export default MenuSider