import axios from 'axios';

import { create } from 'zustand';
import { ValidationError } from '../auth/auth.store';

export interface ChatData {
    role?: 'user' | 'assistant',
    time?: Date,
    content?: string,
    id?: string
}

interface ChatState {
    chats: {
        role?: 'user' | 'assistant',
        time?: Date,
        content?: string
    }[],
    isNewChat: boolean,
    chat_id: null | string,
    error: string | null,
    isLoading: boolean,
    newChat: (message: string) => Promise<void>,
    updateChat: (idChat: string, message: string) => Promise<void>,
    emptyChat: () => void
}

export const useChatStore = create<ChatState>((set, get) => {
    return {
        chats: [],
        isNewChat: true,
        error: null,
        isLoading: false,
        chat_id: null,
        newChat: async (message: string) => {
            try {
                set({
                    isLoading: !get().isLoading, chats: [...get().chats, {
                        role: 'user',
                        content: message,
                        time: new Date()
                    }],
                    isNewChat: false,
                })
                const { data } = await axios.post('/chat/new', {
                    content: message
                });

                const { role, content, time, id }: ChatData = data

                set({ chats: [...get().chats, { role, content, time }], chat_id: id })

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoading: !get().isLoading })
            }
        },

        updateChat: async (id: string, message: string) => {
            if (id === null) throw new Error('Error. Id del chat no encontrado');
            try {
                set({
                    isLoading: !get().isLoading, chats: [...get().chats, {
                        role: 'user',
                        content: message,
                        time: new Date()
                    }]
                })
                const { data } = await axios.put(`chat/` + id, {
                    content: message
                });

                const { role, content, time }: ChatData = data

                set({ chats: [...get().chats, { role, content, time }] })

            } catch (error) {
                let messageError = '';
                if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {

                    if (error?.response?.status === 400) {
                        messageError = 'Datos incorrectos, intentalo nuevamente';
                    } else {
                        messageError = 'Error en el servidor, intentalo mas tarde';
                    }

                } else {
                    messageError = 'Ha ocurrido un error intentalo mas tarde';
                }
                set({ error: messageError })
                setTimeout(() => {
                    set({ error: null })
                }, 2500);

            } finally {
                set({ isLoading: !get().isLoading })
            }
        },
        emptyChat: () => {
            set({ chat_id: null, chats: [], isNewChat: true });
        }

    }
})