
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../components/store/auth/auth.store";

interface PrivateRouteProps {
    component: React.ElementType;
    [key: string]: any; // Resto de props
}


const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    // Add your authentication logic here
    const isAuthenticated = useAuthStore(state => state.isAuthenticated);



    return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/login" replace />;
};
export default PrivateRoute;