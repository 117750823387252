import { FC, useEffect } from "react"
import { DataType } from "../store/vehicles/vehicle.interface"
import { Carousel, Descriptions, DescriptionsProps, Empty, Image, Tag, Typography } from "antd";
import { useVehiclesState } from "../store/vehicles/vehicles.store";


const { Text, Title } = Typography;

const DescripcionVehicle: FC<{
    selectedVehicle: DataType
}> = ({ selectedVehicle }) => {

    const getPhotosVehicle = useVehiclesState(state => state.getPhotosVehicle);
    const photosVehicle = useVehiclesState(state => state.photosVehicle);





    useEffect(() => {
        getPhotosVehicle(selectedVehicle.id);

    }, [])

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: <Text strong>Tipo</Text>,
            children: selectedVehicle.tipoVehiculo,

        },
        {
            key: '2',
            label: <Text strong>Marca</Text>,
            children: selectedVehicle.marcaVehiculo,

        },
        {
            key: '3',
            label: <Text strong>Modelo</Text>,
            children: selectedVehicle.modeloVehiculo
        },

        {
            key: '4',
            label: <Text strong>{'Placa'}</Text>,
            children: selectedVehicle.placa,

        },


        {
            key: '5',
            label: <Text strong>{'Año'}</Text>,
            children: selectedVehicle.anio,

        },
        {
            key: '6',
            label: <Text strong>{'Color'}</Text>,
            children: selectedVehicle.color,

        },
        {
            key: '7',
            label: <Text strong>{'Kilometraje'}</Text>,
            children: selectedVehicle.kilometraje,

        },

        {
            key: '8',
            label: <Text strong>{'Precio'}</Text>,
            children: selectedVehicle.precio,

        },

        {
            key: '9',
            label: <Text strong>{'Cilindraje'}</Text>,
            children: selectedVehicle.cilindraje,

        },

        {
            key: '10',
            label: <Text strong>{'Estado'}</Text>,
            children: <Tag color={'green'} key={'estado'} > {selectedVehicle.estado === 'D' ? 'Disponible' : ''} </Tag>
        },






        {
            key: '12',
            label: <Text strong>{'Combustible'}</Text>,
            children: selectedVehicle.tipoCombustible,

        },
        {
            key: '13',
            label: <Text strong>{'Direccion'}</Text>,
            children: selectedVehicle.tipoDireccion,

        },
        {
            key: '14',
            label: <Text strong>{'Vidrio'}</Text>,
            children: selectedVehicle.tipoVidrio,

        },
        {
            key: '15',
            label: <Text strong>{'Transmision'}</Text>,
            children: selectedVehicle.tipoTransmision,

        },
        {
            key: '16',
            label: <Text strong>{'Traccion'}</Text>,
            children: selectedVehicle.tipoTraccion,

        },
        {
            key: '17',
            label: <Text strong>{'Tapizado'}</Text>,
            children: selectedVehicle.tipoTapizado,

        },

        {
            key: '18',
            label: <Text strong>{'AireAcondicionado'}</Text>,
            children: selectedVehicle.aireAcondicionado,

        },

        /* {
             key: '19',
             label: <Text strong>{'Ubicacion'}</Text>,
             children: selectedVehicle.ubicacionVehiculo,
 
         },*/
        /* {
             key: '20',
             label: <Text strong>{'Propietario'}</Text>,
             children: selectedVehicle.propietarioVehiculo,
         },
 
         {
             key: '21',
             label: <Text strong>{'Matriculado'}</Text>,
             children: selectedVehicle.matriculadoHasta,
 
         },
         {
             key: '22',
             label: <Text strong>{'Oficina'}</Text>,
             children: selectedVehicle.oficinaAuto,
 
         },*/

        /*   {
               key: '23',
               label: <Text strong>{'Observacion'}</Text>,
               children: selectedVehicle.observacion,
   
           },*/

    ];

    return (
        <>
            <Descriptions layout="vertical" bordered items={items} />
            <Title level={4} style={{ margin: '20px 0' }}>Fotos</Title>
            {
                photosVehicle.length > 0 ? (

                    <Carousel arrows dotPosition="left" infinite={true}>
                        {
                            photosVehicle.map((file, i) => (

                                <Image key={i} src={file.image} />
                            ))
                        }
                    </Carousel>
                ) : (
                    <Empty />
                )
            }
        </>
    )
}

export default DescripcionVehicle