import type { FormProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Layout, Row, Typography, } from 'antd';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useAuthStore } from './store/auth/auth.store';
import { message }
    from
    "antd"
    ;
import { useNavigate } from 'react-router-dom';
import Background from './shared/Background';

type FieldType = {
    codigo: string;
    claveAcceso: string;
};
const Login: React.FC = () => {
    const login = useAuthStore(state => state.login);
    const error = useAuthStore(state => state.error);
    const isLoading = useAuthStore(state => state.isLoading);
    const navigate = useNavigate();
    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        await login(values);
        navigate('/');
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    /* const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
 
     const showDrawer = () => {
         setDrawerVisible(true);
     };
 
     const closeDrawer = () => {
         setDrawerVisible(false);
     };*/

    const [height, setHeight] = useState(window.innerHeight);
    const updatedHeight = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {


        window.addEventListener('resize', updatedHeight);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', updatedHeight);
        };

    }, [])


    useEffect(() => {
        if (error !== null) {
            message.error({
                content: error
            })
        }
    }, [error]);

    return (
        <>
            <Background />
            <Layout style={{ minHeight: height, display: 'flex', justifyContent: 'center', overflowY: 'hidden' }}>
                <Row justify="center" align="middle" >
                    <Col xs={24} sm={20} md={16} lg={12} xl={7}>
                        <Card style={{ paddingTop: 20, backgroundColor: 'rgb(245 248 255 / 92%)' }}>

                            <Typography.Title level={3} style={{ textAlign: 'center', paddingBottom: 20 }} >CORPOILALO</Typography.Title>
                            <Form
                                name="basic"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 18 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"

                            >
                                <Form.Item<FieldType>
                                    label="Usuario"
                                    name="codigo"

                                    rules={[{ required: true, message: 'Ingresa el correo!' }]}
                                >
                                    <Input prefix={<UserOutlined />} />
                                </Form.Item>

                                <Form.Item<FieldType>
                                    label="Contraseña"
                                    name="claveAcceso"

                                    rules={[{ required: true, message: 'Ingresa una contraseña!' }]}
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>



                                <Form.Item wrapperCol={{ offset: 10, span: 14 }} style={{ paddingTop: 20 }}>
                                    <Button loading={isLoading} type="primary" htmlType="submit">
                                        Ingresar
                                    </Button>
                                </Form.Item>

                            </Form>

                        </Card>
                    </Col>
                </Row>
            </Layout >
        </>
    )
}

export default Login