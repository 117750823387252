import { FC } from 'react'
import FolderIcon from './svg/Folder.icon';
import MsExcelIcon from './svg/MsExcel.icon';
import MsPowerPointIcon from './svg/MsPowerPoint.icon';
import { File01Icon, FileZipIcon, Pdf01Icon } from 'hugeicons-react';
import VideoIcon from './svg/VideoIcon';
import PhotoIcon from './svg/Photo.icon';
import MsWordIcon from './svg/MsWord.icon';


const IconType: FC<{
    typeNode: string,
    name: string,
    size: number,
}> = ({ typeNode, name, size }) => {
    if (typeNode === 'C') {
        return <FolderIcon size={size} />;
    } else if (typeNode === 'A') {
        const type = name.split('.')[1];
        console.log(name);
        switch (type) {
            case 'xlsx':
            case 'xls':
            case 'xlsm':
                return <MsExcelIcon size={size} />
            case 'ppt':
            case 'pptx':
                return <MsPowerPointIcon size={size} />
            case 'doc':
            case 'docx':
                return <MsWordIcon size={size} />
            case 'pdf':
                return <Pdf01Icon color='red' size={size} />
            case 'rar':
            case 'zip':
                return <FileZipIcon size={size} />
            case 'mp4':
            case 'mpg':
            case 'mk4':
                return <VideoIcon size={size} />

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
                return <PhotoIcon size={size} />
            default:
                return <File01Icon color='blue' size={size} />

        }



    }

}

export default IconType;